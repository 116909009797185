import { Outlet, useLocation } from "react-router-dom";
import { WishlistProvider } from "../Context/WishlistContext";
import MainNavigation from "./MainNavigation";
import Footer from "./footer";
import { useEffect } from "react";
import { PhoneProvider } from "../Context/PhoneContext"; // Import PhoneContext
export default RootLayout;


function RootLayout() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (

        <PhoneProvider>
            <WishlistProvider>
                <div>
                    <MainNavigation />
                    <Outlet />
                    <Footer />
                </div>
            </WishlistProvider>
        </PhoneProvider>

);
}
