import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from "axios";
import Property from "../Components/Property";
import SecondaryBtn from "../Components/SecondaryBtn";
import FilterBar from "../Components/sideMenu";
import Spinner from "../Components/Spinner"; // Import the Spinner component
import Filter from '../images/icons/filter.svg';
import Map from '../images/icons/harta.svg';
import {useWishlist} from "../Context/WishlistContext";

function RealEstateListPage(props) {
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [image, setImage] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const newRef = useRef(null);
    const { type } = useParams();
    const { isInWishlist, toggleWishlist } = useWishlist();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [propertiesResponse, imagesResponse] = await Promise.all([
                    axios.get('https://project1.lbi.studio/api/api/Properties.php?allProperties'),
                    axios.get('https://project1.lbi.studio/api/api/Images.php?allImages')
                ]);

                if (propertiesResponse.data && propertiesResponse.data.properties) {
                    let propertiesData = propertiesResponse.data.properties.filter(item => {
                        return props.chirie ? item.trans_id === '14' : item.trans_id === '16';
                    });

                    setData(propertiesData);
                    setFilterData(propertiesData);
                } else {
                    console.error('No properties found in response:', propertiesResponse.data);
                }

                if (imagesResponse.data) {
                    setImage(imagesResponse.data);
                } else {
                    console.error('No images found in response:', imagesResponse.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [props]);

    const handleClick = () => {
        setIsActive(!isActive);
    };

    const handleClose = () => {
        setIsActive(false);
    };

    const applyFilters = useCallback((filteredProperties) => {
        setFilterData(filteredProperties);
    }, [setFilterData]);

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);
    };

    let apartamente = null;
    if (image.length > 0 && filterData.length > 0) {
        // Sort the images array by main_image in ascending order
        const sortedImages = image.sort((a, b) => a.main_image.localeCompare(b.main_image));

        apartamente = filterData.map((item) => {
            // Find the first image after sorting for the specific property
            const mainImage = sortedImages.find((imgEl) => imgEl.property_id === item.property_id);

            return (

                <Property
                    key={item.property_id}
                    image={mainImage}
                    price={parseFloat(item.price).toLocaleString('de-DE')}
                    location={item.property_name}
                    bedrooms={item.rooms}
                    bathrooms={item.baths}
                    sqrM={item.use_sqm}
                    propertyId={item.property_id} // Pass property_id
                    propertyName={item.property_name} // Pass property name
                    isInWishlist={isInWishlist(item.property_id)} // Check if the property is in wishlist
                    toggleWishlist={toggleWishlist} // Pass the toggleWishlist function
                />
            )
        });
    }

    return (
        <div>
            <div className='searchBar'>
                <input
                    type='text'
                    className='searchInput'
                    placeholder='Introdu ID sau termen de cautare'
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>
            <FilterBar
                className={`ScrollAreaRoot ${isActive ? 'ShowFilterBar' : 'HiddenFilterBar'}`}
                initialPropertyType={type}
                ref1={newRef}
                data={data}
                searchText={searchTerm}
                setFilteredData={applyFilters}
                handleClose={handleClose}
            />

            <div className='propertyGridContainer'>
                <div className='containerBtn'>
                    <div className='gridItem1'><SecondaryBtn src={Map} alt='Harta Proprietatilor'>vezi harta</SecondaryBtn></div>
                    <span className='gridItem2 nrProprietati'>{`${filterData.length} proprietati`}</span>
                    <div className='gridItem3 filterBtn linkPointer' onClick={handleClick}>
                        <img src={Filter} alt='Filter Icon' />Filtreaza
                    </div>
                </div>

                {loading ? (
                    <div className='spinner-wrapper'><Spinner /></div> // Center spinner in place of content
                ) : (
                    apartamente
                )}
            </div>
        </div>
    );
}

export default RealEstateListPage;
