import './Property.css';
import locationIcn from '../images/icons/locatie.svg';
import dormitorIcn from '../images/icons/dormitor.svg';
import baieIcn from '../images/icons/baie.svg';
import sqmIcn from '../images/icons/suprafata.svg';
import heartIcn from '../images/icons/favorite.svg';
import filledHeartIcn from '../images/icons/filledFavorite.svg';
import { useWishlist } from '../Context/WishlistContext'; // Import WishlistContext
import { Link } from 'react-router-dom';

function Property(props) {
    const { isInWishlist, toggleWishlist } = useWishlist(); // Access wishlist state and toggle function
    const imagePath = props.image === undefined ? 'images/properties/house1.png' : props.image.image_path;

    const inWishlist = isInWishlist(props.propertyId); // Check if the specific property is in the wishlist

    const handleWishlistClick = (e) => {
        e.preventDefault(); // Prevent the link from activating

        // Toggle wishlist state, passing only the property ID
        toggleWishlist(props.propertyId);
    };

    return (
        <div className='propertyContainer'>
            <Link to={`/product/${props.propertyId}`} className="noStyleLink">
                <img className='imageGridProperty' src={imagePath} alt={props.location} />
            </Link>
            <div className='propertyContent'>
                <div className='propertyFlex'>
                    <img
                        src={inWishlist ? filledHeartIcn : heartIcn}
                        alt='Favorite'
                        onClick={handleWishlistClick} // Trigger the wishlist toggle with property ID
                        className='wishlistIcon linkPointer'
                    />
                    <h3 className='price'>{props.price} €</h3>
                </div>
                <div className='propertyFlex'><img src={locationIcn} alt='Locatie'/><p>{props.location}</p></div>
                <div className='propertyFlexLeft'>
                    <div className='propertyFlexItem'><img src={dormitorIcn} alt='Dormitoare'/><span className='propertiesCounter'>{props.bedrooms}</span></div>
                    <div><img src={baieIcn} alt='Bai' /><span className='propertiesCounter'>{props.bathrooms}</span></div>
                    <div><img src={sqmIcn} alt='Suprafata' /><span className='propertiesCounter'>{props.sqrM} m2</span></div>
                </div>
            </div>
        </div>
    );
}

export default Property;
